<template>
  <div
    class="modal fade"
    id="editBaixaModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img src="../assets/ico-lapide.svg" /> BAIXAS
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <div v-if="!lossesList || lossesList.length === 0" class="no-losses-message">
            <img src="../assets/ico-lapide.svg" />
            <p>Sem baixas registradas neste lote</p>
          </div>
          
          <div v-else class="losses-list">
            <div v-for="loss in lossesList" :key="loss.id" class="loss-item">
              <div class="loss-info">
                <div class="loss-detail">
                  <span class="label">Peso:</span>
                  <span>{{ loss.weight }} kg</span>
                </div>
                <div class="loss-detail">
                  <span class="label">Quantidade:</span>
                  <span>{{ loss.quantity }}</span>
                </div>
                <div class="loss-detail">
                  <span class="label">Razão:</span>
                  <span>{{ formatReason(loss.reason) }}</span>
                </div>
                <div class="loss-detail">
                  <span class="label">Data:</span>
                  <span>{{ formatDate(loss.death_datetime) }}</span>
                </div>
                <div class="loss-detail">
                  <span class="label">Detalhes:</span>
                  <span>{{ loss.death_details || '-' }}</span>
                </div>
              </div>
              <div class="loss-actions">
                <button @click="editLoss(loss)" class="btn-edit">
                  <i class="fas fa-edit"></i>
                </button>
                <button @click="deleteLoss(loss)" class="btn-delete">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- Modal de edición -->
          <div v-if="showEditForm" class="edit-form">
            <h6>Editar Baixa</h6>
            <form @submit.prevent="updateLoss">
              <input
                type="number"
                class="form-control"
                placeholder="Peso em kg"
                v-model="editingLoss.weight"
                required
              />

                <input
                    type="number"
                    class="form-control"
                    placeholder="Quantidade"
                    v-model="editingLoss.quantity"
                    step="1"
                    required
                />

              <select class="form-control" v-model="editingLoss.reason" required>
                <option value="pneumonia">Pneumonia</option>
                <option value="diarrhea">Diarreia</option>
                <option value="blood diarrhea">Diarreia com sangue</option>
                <option value="sudden death">Morte súbita</option>
                <option value="twist">Torção</option>
                <option value="refuse/eliminated">Refugo/Eliminado</option>
                <option value="ulcer">Úlcera</option>
                <option value="prolapse">Prolapso</option>
                <option value="encephalitis">Encefalite</option>
                <option value="other">Outros</option>
              </select>

              <textarea
                class="form-control"
                placeholder="Detalhes da morte"
                v-model="editingLoss.death_details"
              ></textarea>

              <input
                type="datetime-local"
                class="form-control"
                v-model="editingLoss.death_datetime"
                required
              />

              <div class="edit-actions">
                <button type="submit" class="btn-save">Salvar</button>
                <button type="button" @click="cancelEdit" class="btn-cancel">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess"/>
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure"/>
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";

export default {
  name: "EditLossModal",
  components: {
    ErrorAlert,
    SuccessAlert
  },
  data() {
    return {
      showEditForm: false,
      editingLoss: null,
      messageSuccess: "baixa atualizada com sucesso",
      messageFailure: "não foi possível atualizar a baixa"
    }
  },
  computed: {
    lossesList() {
      return this.$store.getters.getLossesBatch;
    },
    batch() {
      return this.$store.getters.getBatchTarget;
    }
  },
  async mounted() {
    if (this.batch && (!this.lossesList || this.lossesList.length === 0)) {
      try {
        await this.$store.dispatch('fecthLossesByBatch', {
          batch: this.batch.pk,
          url: null,
          cache: 'no-store'
        });
      } catch (error) {
        console.error('Error fetching losses:', error);
        this.$refs.errorAlert.alert();
      }
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    formatReason(reason) {
      const reasonMap = {
        'pneumonia': 'Pneumonia',
        'diarrhea': 'Diarreia',
        'blood diarrhea': 'Diarreia com sangue',
        'sudden death': 'Morte súbita',
        'twist': 'Torção',
        'refuse/eliminated': 'Refugo/Eliminado',
        'ulcer': 'Úlcera',
        'prolapse': 'Prolapso',
        'encephalitis': 'Encefalite',
        'other': 'Outros'
      };
      return reasonMap[reason] || reason;
    },
    editLoss(loss) {
      const formattedLoss = { ...loss };
      
      if (formattedLoss.death_datetime) {
        const date = new Date(formattedLoss.death_datetime);
        formattedLoss.death_datetime = date.toISOString().slice(0, 19);
      }

      this.editingLoss = formattedLoss;
      this.showEditForm = true;
    },
    async deleteLoss(loss) {
      if (confirm('Tem certeza que deseja excluir esta baixa?')) {
        try {
          await this.$store.dispatch("deleteLoss", loss);
          this.$refs.successAlert.alert();
          
        } catch (error) {
          console.error('Error al eliminar la baixa:', error);
          this.$refs.errorAlert.alert();
        }
      }
    },
    async updateLoss() {
      try {
        const formattedLoss = {
          ...this.editingLoss,
          weight: Number(this.editingLoss.weight),
          quantity: Number(this.editingLoss.quantity),
          death_datetime: new Date(this.editingLoss.death_datetime)
            .toLocaleString('sv', { timeZone: 'America/Sao_Paulo' })
            .replace(' ', 'T') + '-03:00'
        };

        await this.$store.dispatch("updateLoss", formattedLoss);
        
        this.showEditForm = false;
        this.editingLoss = null;
        this.$refs.successAlert.alert();
      } catch (error) {
        console.error('Error al actualizar la baixa:', error);
        this.$refs.errorAlert.alert();
      }
    },
    cancelEdit() {
      this.showEditForm = false;
      this.editingLoss = null;
    },
    handleClose() {
      this.$refs.closeButton.click();
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  max-width: 800px;
}

.losses-list {
  max-height: 400px;
  overflow-y: auto;
}

.loss-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
}

.loss-info {
  flex: 1;
}

.loss-detail {
  margin-bottom: 4px;
}

.loss-detail .label {
  font-weight: bold;
  margin-right: 8px;
}

.loss-actions {
  display: flex;
  gap: 8px;
}

.btn-edit, .btn-delete {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-edit {
  background-color: #78b843;
  color: white;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}

.edit-form {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #eee;
}

.edit-form h6 {
  margin-bottom: 16px;
}

.form-control {
  margin-bottom: 12px;
}

.edit-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 16px;
}

.btn-save, .btn-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-save {
  background-color: #78b843;
  color: white;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

.no-losses-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}

.no-losses-message img {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  opacity: 0.6;
}

.no-losses-message p {
  font-size: 18px;
  color: #666;
  margin: 0;
}
</style>
