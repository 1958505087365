<template>
  <div >
    <div class="container-inputs-buton">
        <div class="sensor-name">
            {{ sensorName }}
        </div>
    </div>
    <Loader v-if="loading" style="margin: 100px;"/>
    <div class="silo-viz-charts" v-else>
      <div class="container-chart-message" v-if="validAndFixedSiloProfiles.length > 0 ? true : false">
        <div class="container-canvas-chart"
      >
        <SiloProfileVisualizationCanvas
          style="width: 100%; height: 100%;"
          :siloSeries="hoveredSiloSeries"
          :profileSeries="hoveredProfileSeries"
          :fillGrainArea="fillGrainArea"
          :siloMeasures="siloMeasures"
          :verifiedProfileSeries="verifiedProfileSeries"
        />
        <SiloProfileHistoryChart
          style=" width: 100%; height: 100%;"
          :silo_profiles="validAndFixedSiloProfiles"
          @profileHover="changeHoveredProfile"
        />
    </div>
    <div class="container-table">
      <table class="silo-viz-table">
          <tr>
            <td>Data-hora</td>
            <td>{{ profileHovered === undefined ? '' :  hoveredProfileDatetimeHuman }}</td>
          </tr>
          <tr>
            <td>Nivel</td>
            <td>{{ profileHovered === undefined ? '' :  weightLevelPercentage.toFixed(2) }}%</td>
          </tr>
          <tr>
            <td>Volume</td>
            <td>{{ profileHovered === undefined ? '' :  formatNumberWithThousandSeparator(volumeFromWeight(profileHovered.weight)) }} m³</td>
          </tr>
          <tr>
            <td>Peso</td>
            <td>{{ profileHovered === undefined ? '' :  formatNumberWithThousandSeparator(weightLevelKg) }} kg</td>
          </tr>
          <tr>
            <td>Sacas</td>
            <td>{{ profileHovered === undefined ? '' :  formatNumberWithThousandSeparator(numberOfSacs) }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="container-warnig">
      <div class="warning" v-if="validAndFixedSiloProfiles.length > 0 ? false : true">
        <div class="text">
          <div style="text-align: center">Sem dados  para o período solicitado. Por favor, verifique se o sensor não está soterrado e cheque se o sensor e o painel estão energizados e com conexão à internet. Caso não haja nenhum desses problemas, favor entrar em contato com o suporte PecSmart.</div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { URL } from "../store/index"
import SiloProfileHistoryChart from "./SiloProfileHistoryChart.vue";
import SiloProfileVisualizationCanvas from "./SiloProfileVisualizationCanvas.vue";

import moment from 'moment'
import { subDays, format } from 'date-fns'
// import { zip } from 'lodash'

export default {
  name: "SmartFeedProfileHistory",
  props: ["sensor", "sacaWeight", "startDate", "endDate", "seekNewRange", "useInformedWeight"],
  components: {
    Loader,
    SiloProfileHistoryChart,
    SiloProfileVisualizationCanvas,
  },
  data() {
    return {
      showMessage :false,
      limitDiffDays: 90,
      loading: true,
      siloProfiles: undefined,
      profileHovered: undefined,
      siloPk: undefined,
      currentFoodDensity: undefined,
      siloMeasures: null
    };
  },
  watch: {
    seekNewRange() {
      this.fetchSiloProfiles(this.startDate, this.endDate)
    }
  },
  methods: {
    xySeries(profileCurve) {
      return profileCurve.points.x.map((x, idx) => ({ x, y: profileCurve.points.y[idx] }))
    },
    formatNumberWithThousandSeparator: function(n) {
      return n.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    volumeFromWeight: function(weight) {
      return weight/this.currentFoodDensity
    },
    changeHoveredProfile: function(profile) {
      this.profileHovered = profile
    },
    fetchSiloProfiles: async function(initDate, endDate) {
      this.loading = true;
      try {
        const request_info = {
          "sensor_pk": parseInt(this.sensor.sensor),
          "start_date": initDate,
          "end_date": endDate,
        }
        const response = await fetch(`${URL}silo_profiles/silo_profile_history/`, {
          method: "POST",
          body: JSON.stringify(request_info),
          headers: this.$store.getters.getHeader,
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseText = await response.text();
        const sanitizedText = responseText.replace(/-Infinity/g, null).replace(/Infinity/g, null);
        try {
          const parsedData = JSON.parse(sanitizedText);
          if (Array.isArray(parsedData)) {
            this.siloProfiles = parsedData.filter(profile => profile.weight !== null || profile.level !== null);
          }

          if (this.siloProfiles && this.siloProfiles.length > 0) {
            this.siloPk = this.siloProfiles[0].silo;
            await this.fetchFoodDensity();
          } else {
            console.warn('No silo profiles data in response');
          }
        } catch (parseError) {
          this.loading = false
          console.error("Error parsing JSON:", parseError);
          this.siloProfiles = [];
        }
      } catch(error) {
        this.loading = false;
        console.error("Error fetching silo profiles:", error);
        return;
      }
      this.loading = false;
    },
    fetchFoodDensity: async function() {
      this.loading = true
      try {
          const siloResponse = await fetch(`${URL}silos/${this.siloPk}`, { method: "GET", headers: this.$store.getters.getHeader,})
          const silo = await siloResponse.json()
          this.siloMeasures = silo
          const foodPk = silo.food
          const foodResponse = await fetch(`${URL}foods/${foodPk}`, { method: "GET", headers: this.$store.getters.getHeader })
          const food = await foodResponse.json()
          this.currentFoodDensity = food.density
        }
        catch(error) {
          this.loading = false
          console.error("Could not get food density.", error)
          return;
        }
      this.loading = false
    },
    isAiTurnedOff(points){
      const areUntilTop = points[0].y == points[points.length - 1].y
      const dataCurveSiloDimensions = this.validAndFixedSiloProfiles[0].curves[0]

      const verifyZeroPosition = points.some((point) => point.x == 0 && point.y == 0)

      return !verifyZeroPosition || (areUntilTop && (points[0].y == dataCurveSiloDimensions.points.y[0]))
    }
  },
  computed: {
    sensorName(){
      return this.sensor.name
    },
    informedMaxWeight(){
      return this.sensor.informed_max_weight
    },
    weightLevelKg() {
      if (this.informedMaxWeight == null) return this.profileHovered.weight
      if (this.useInformedWeight) return (this.informedMaxWeight * this.profileHovered.level * 0.009)
      return this.profileHovered.weight
    }, 
    weightLevelPercentage() {
      if (this.informedMaxWeight == null) return this.profileHovered.level
      if (this.useInformedWeight) return (this.profileHovered.level * 0.9)
      return this.profileHovered.level
    }, 
    dateSevenDaysAgo() {
      return format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss') 
    },
    numberOfSacs() {
      return (this.profileHovered.weight / this.sacaWeight)
    },
    hoveredProfileDatetimeHuman() {
      return moment(this.profileHovered.datetime_fetched).format("DD/MM/YY - H:mm")
    },
    validSiloProfiles() {
      if (this.siloProfiles === undefined) {
        return [{
          name: "Nivel",
          data: []
        }]
      }
      return this.siloProfiles
        .filter((p) => {
          return p.silo_feed_weight !== undefined
        })
        .filter((p) => {
          return p.level > 0
        })
        .filter((p, idx, array) => {
          if ([array[idx - 1], array[idx - 5], array[idx - 9]].includes(undefined)) return true
          const diffBehind = (i) => Math.abs(p.level - array[idx - i].level)
          return [diffBehind(1), diffBehind(5), diffBehind(9)].every(diff => diff < 5)
        })
    },
    validAndFixedSiloProfiles() {
        if (this.validSiloProfiles.length > 1) {
          this.validSiloProfiles
          .map((profile) => {
            return profile.curves.forEach((curve) => {
              return curve
            })
          })
          return this.validSiloProfiles
        } else {
          return []
        }
    },
    hoveredSiloSeries() {
      if (this.validAndFixedSiloProfiles.length > 0 ) {
        return this.profileHovered === undefined ? this.xySeries(this.validAndFixedSiloProfiles[0].curves[0]) : this.xySeries(this.profileHovered.curves[0])
      } else{
        return []
      }
    },
    hoveredProfileSeries() {
      this.verifyPorfileSeries
        if (this.validAndFixedSiloProfiles.length > 0 ) {
          return this.profileHovered === undefined ? this.xySeries(this.validAndFixedSiloProfiles[0].curves[4]) : this.xySeries(this.profileHovered.curves[4])
        } else {
          return  []
        }
    },
    verifiedProfileSeries(){
      if (!this.isAiTurnedOff(this.hoveredProfileSeries)) {
        return this.hoveredProfileSeries
      } else {
        const dataDimensions = [...this.hoveredSiloSeries]
        const dataPoints = [...this.hoveredProfileSeries]

        const deletePoints = dataPoints.filter((point, index) => {
          return index > 0 && index < dataPoints.length - 1
        })

        const dataDimensionsDeletePoints = dataDimensions.filter((point, index) => {
          return index > 0 && index < dataDimensions.length - 1
        })

        const reverseDataDimensions = dataDimensionsDeletePoints.reverse()

        const sortedDeletePoints = deletePoints.sort((a, b) => a.x - b.x)

        const newDataPoints = [...sortedDeletePoints, ...reverseDataDimensions]

        return newDataPoints
      }
    },
    fillGrainArea() {
      return this.profileHovered === undefined ? false : new Date(this.profileHovered.datetime_fetched) > new Date('02/12/2024 23:59:59') && this.profileHovered.level > 0.2
    }
  },
  async created() {
    await this.fetchSiloProfiles(this.dateSevenDaysAgo, this.endDate)
  },
};
</script>

<style scoped>
.sensor-name {
  font-size: 24px;
  font-weight: bold;
}


.silo-download-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.silo-viz-charts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-inputsDate{
  display: flex;
  margin:4px;
  align-items: center;
  justify-content: center;
}

.container-inputs-buton{
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.container-buton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.silo-viz-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
}

.container-table{
  display: flex;
  justify-content: end;
}

.silo-viz-table td, .silo-viz-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.silo-viz-table tr:nth-child(even){background-color: #f2f2f2;}

.silo-viz-table tr:hover {background-color: #ddd;}

.silo-viz-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.silo-download-form button {
  background-color: #e8e8e8;
  border: 2px solid #78b843;
  border-radius: 5px;
}

.container-warnig {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning {
  max-width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.buttomDate{

  margin:4px;
  padding: 8px;
  border-radius: 8px;
  background-color: #78b843 ;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  transition: background-color 0.2s ease-out;
}

.buttomDate:hover{
  background-color: #1e7e34;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 16px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.container-canvas-chart{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction:row
}

@media screen and (max-width: 540px){
  .container-canvas-chart{
  flex-direction: column;
}
}


@media screen and (max-width: 1091px){
  .container-inputs-buton{
    flex-direction: column;
  }
  .container-canvas-chart{
    flex-direction: column;
  }
}
</style>
