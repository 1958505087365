<script>
import { Bar } from 'vue-chartjs'

export default {
  name: "SmartFeedChart",
  extends: Bar,
  props: ["sets", "text", "offset", "yAxesScale", "first_date", "last_date"],
  data() {
    return {
      weightData: {
        datasets: this.sets
      },
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label
            },
            beforeLabel: function(tooltipItem) {
              return `Dia: ${tooltipItem.xLabel}`;
            },
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              return `${label}: ${tooltipItem.yLabel} kg`
            },
          },
          backgroundColor: '#fff',
          titleFontSize: 16,
          titleFontColor: '#89c25b',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false,
          borderColor: 'rgba(137, 194, 91, 1)',
          borderWidth: 2,
          custom: function(tooltipModel) {
            let title;
            if (tooltipModel.title != null) {
              title = tooltipModel.title[0]
            }
            else {
              title = ''
            }

            if (title === "Abastecimento Diário" || title === "Abastecimento Acumulado") {
              tooltipModel.borderColor = '#999'
              tooltipModel.titleFontColor = '#999'
            }
            else {
              tooltipModel.titleFontColor = 'rgba(137, 194, 91)';
            }
          }
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              offset: this.offset,
              scaleLabel: {
                display: false,
                fontSize: 16
              },
              gridLines: {
                color: "rgba(120, 184, 67, .125)",
                display: true,
              },
              type: 'time',
              ticks: {
                min: this.first_date,
                max: this.last_date,
              },
              time: {
                unit: 'day'
              },
            },
          ],
          yAxes: [
            {
              id: 'Consume',
              display: this.yAxesScale,
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return `${value} kg`;
                }
              },
            }, 
            {
              id: 'AccumulatedConsume',
              display: !this.yAxesScale,
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return `${value} kg`;
                }
              },
            },
          ],
        },
        layout:{
          padding: {
            left: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        stroke: {
          curve: 'straight'
        },
      }
    }
  },
  mounted () {
    this.renderChart(this.weightData, this.options)
  },
}
</script>
