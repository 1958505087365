<template>
  <div id="">
    <Loader v-if="loading" />

    <div v-if="!loading">
      <div class="row">
        <div class="col-lg-12">
          <div style="position: relative;" class="conteudo">
            <div v-if="fetchFailed === true && userLevel == 'pecsmart'" class="chart-without-data">
              <h1 class="error-message">{{errorMessage}}</h1>
            </div>
            <div v-else-if="fetchFailed === true && userLevel !== 'pecsmart'" class="chart-without-data">
              <h1 class="error-message">SEM DADOS</h1>
              <h1 class="error-message">DE CONSUMO DISPONÍVEIS</h1>
            </div>

            <div v-else-if="chartWarning(siloUnloadDataset) && chartWarning(siloUnloadDatasetAccumulated)" class="chart-without-data">
              <h1>SEM DADOS</h1>
            </div>

            <div class="chart-title-main">
              <div class="chart-header">
                <div class="header-section"></div>
                <div class="chart-title">
                  <h2>CONSUMO CONSOLIDADO NO LOTE ATUAL</h2>
                  <p class="chart-subtitle">{{ formatDateRange }}</p>
                </div>
                <div class="export-dropdown header-section">
                  <button class="export-btn" @click="toggleDropdown">
                    <i class="fas fa-download"></i>
                    <span>Exportar</span>
                    <i class="fas fa-chevron-down"></i>
                  </button>
                  <div class="dropdown-content" v-if="showDropdown">
                    <a @click="exportToPDF">
                      <i class="fas fa-file-pdf"></i>
                      <span>PDF</span>
                    </a>
                    <a @click="exportToExcel">
                      <i class="fas fa-file-excel"></i>
                      <span>Excel</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="chart-active">
              <div class="chart-title">
                <p>Diário</p>
              </div>

              <SliderButton
                v-bind:TrackType="'track-unload'"
                @changeChart="changeUnloadChart()"
              />

              <div class="chart-title">
                <p>Acumulado</p>
              </div>
            </div>

            <SmartFeedChart
              ref="chartComponent"
              :key="componentKeyUnload"
              v-bind:sets="unloadChartDisplay"
              v-bind:yAxesScale="yAxesScaleUnload"
              v-bind:offset="chartOffsetUnload"
              v-bind:first_date="chartStartDate"
              v-bind:last_date="chartEndDate"
              :height="200"
              :width="800"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartFeedChart from "./SmartFeedChart.vue";
import SliderButton from "./SliderButton.vue";
import Loader from "./Loader.vue";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { generateConsolidatePDF } from '../pdf/pdfConsolidated';
import { generateConsolidateExcel } from '../excel/excelConsolidated';

export default {
  name: "SmartFeed",
  props: ["sensor", "workingSensors", "startDate", "endDate", "seekNewRange", "allFoods"],
  components: {
    SmartFeedChart,
    SliderButton,
    Loader,
  },
  data() {
    return {
      loading: true,
      fetchFailed: false,
      isAccumulatedView: false,
      chartOffsetUnload: true,
      yAxesScaleUnload: true,
      componentKeyUnload: 0,
      showDropdown: false,
    };
  },
  methods: {
    formatToYYYYMMDD(dateString) {
      if (!dateString) return '';
      try {
        // Si la fecha tiene formato ISO o incluye tiempo
        if (dateString.includes('T') || dateString.includes(' ')) {
          return format(parseISO(dateString), 'yyyy-MM-dd');
        }
        // Si ya está en formato YYYY-MM-DD
        return dateString;
      } catch (error) {
        console.error('Error formatting date:', error);
        return dateString;
      }
    },
    chartWarning(dataset) {
      if (dataset.length < 1) return true;
      return false;
    },
    changeUnloadChart() {
      // Toggle between accumulated and daily view
      this.isAccumulatedView = !this.isAccumulatedView;
      
      // Force rerender to update the chart
      this.forceRerenderUnload();
    },
    forceRerenderUnload: function() {
      this.componentKeyUnload += 1;
    },
    exportToPDF() {
      try {
        if (!this.sensorsData || !this.sensorsData.data) {
          console.error('No hay datos para exportar');
          return;
        }

        // Filtrar los datos según el rango de fechas seleccionado
        const filteredData = this.sensorsData.data.filter(item => {
          const itemDate = this.formatToYYYYMMDD(item.date);
          return itemDate >= this.chartStartDate && itemDate <= this.chartEndDate;
        });

        generateConsolidatePDF(
          filteredData, 
          true, 
          this.workingSensors, 
          this.allFoods,
          {
            farmName: this.farmName,
            batchName: this.batchName.replace('- LOTE ', ''),
            barnName: this.barnName
          }
        );
      } catch (error) {
        console.error('Error al generar PDF:', error);
      }
    },
    exportToExcel() {
      try {
        if (!this.sensorsData || !this.sensorsData.data) {
          console.error('No hay datos para exportar');
          return;
        }

        // Filtrar los datos según el rango de fechas seleccionado
        const filteredData = this.sensorsData.data.filter(item => {
          const itemDate = this.formatToYYYYMMDD(item.date);
          return itemDate >= this.chartStartDate && itemDate <= this.chartEndDate;
        });

        generateConsolidateExcel(
          filteredData, 
          this.workingSensors, 
          this.allFoods,
          {
            farmName: this.farmName,
            batchName: this.batchName.replace('- LOTE ', ''),
            barnName: this.barnName
          }
        );
      } catch (error) {
        console.error('Error al generar Excel:', error);
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
  computed: {
    unloadChartDisplay() {
      return this.isAccumulatedView ? this.unloadAccData : this.unloadData;
    },
    computed_level() {
      if (this.sensorsData?.last_hour_level == null) return "Sem Nível";
      return `${this.sensorsData.last_hour_level}%`;
    },
    computed_weight() {
      if (this.sensorsData?.last_hour_weight == null) return "Sem Peso";
      return `${this.sensorsData.last_hour_weight} kg`;
    },
    sensorsData() {
      try {
        return this.$store.getters.getSmartFeedConsolidatedData.find(
          (item) => item.barn === this.barn.pk
        );
      } catch (err) {
        return null;
      }
    },
    siloUnloadDataset() {
      try {
        const startDate = this.startDate ? this.formatToYYYYMMDD(this.startDate) : this.formatToYYYYMMDD(this.batch.date_accomodation);
        const endDate = this.endDate ? this.formatToYYYYMMDD(this.endDate) : (this.batch.date_departure ? this.formatToYYYYMMDD(this.batch.date_departure) : this.formatToYYYYMMDD(new Date()));

        return this.sensorsData.data
          .filter(item => {
            const itemDate = this.formatToYYYYMMDD(item.date);
            return itemDate >= startDate && itemDate <= endDate;
          })
          .map((item) => {
            return {
              x: item.date,
              y: Number(item.consume).toFixed(2),
            };
          });
      } catch (err) {
        return [];
      }
    },
    siloUnloadDatasetAccumulated() {
      try {
        const startDate = this.startDate ? this.formatToYYYYMMDD(this.startDate) : this.formatToYYYYMMDD(this.batch.date_accomodation);
        const endDate = this.endDate ? this.formatToYYYYMMDD(this.endDate) : (this.batch.date_departure ? this.formatToYYYYMMDD(this.batch.date_departure) : this.formatToYYYYMMDD(new Date()));

        let accumulated = 0;
        return this.sensorsData.data
          .filter(item => {
            const itemDate = this.formatToYYYYMMDD(item.date);
            return itemDate >= startDate && itemDate <= endDate;
          })
          .map((item) => {
            let y = item.consume + accumulated;
            accumulated += item.consume;
            return {
              x: item.date,
              y: Number(y).toFixed(2),
            };
          });
      } catch (err) {
        return [];
      }
    },
    unloadData() {
      const setting = [
        {
          label: "Consumo Diário",
          pointRadius: 3,
          data: this.siloUnloadDataset,
          type: "bar",
          fill: false,
          borderColor: "rgba(137, 194, 91, 1)",
          backgroundColor: "rgba(137, 194, 91, 1)",
          borderWidth: 2,
          yAxisID: "Consume",
          barPercentage: 0.75,
        },
      ];
      return setting;
    },
    unloadAccData() {
      const setting = [
        {
          label: "Consumo Acumulado",
          pointRadius: 3,
          data: this.siloUnloadDatasetAccumulated,
          type: "line",
          fill: true,
          borderColor: "rgba(91, 138, 52, 0.9)",
          backgroundColor: "rgba(91, 138, 52, 0.9)",
          borderWidth: 2,
          yAxisID: "AccumulatedConsume",
        },
      ];
      return setting;
    },
    barn() {
      return this.$store.getters.getBarnTarget;
    },
    batch() {
      if (this.$store.getters.getBatchTarget == null) {
        return this.barn.batch
      }
      return this.$store.getters.getBatchTarget
    },
    barnName() {
      if (this.barn == null) {
        return ""
      }
      return `${this.barn.name}`
    },
    batchName() {
      if (this.batch == null) return ''
      return `- LOTE ${this.batch.pk}`
    },
    farmName() {
      if (this.barn) {
          return this.$store.getters.getAllBarns.find((obj)=> {
          return obj.barn.find((barn)=>barn.pk == this.barn.pk)
        }).farm.name
      }
      return ''
    },
    last_date() {
      if (this.batch.date_departure != null) return this.batch.date_departure;
      let today = new Date();
      today.setDate(today.getDate());
      return today;
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
    errorMessage() {
      return this.$store.getters.getErrorMessage
    },
    formatDateRange() {
      const startDate = format(parseISO(this.batch.date_accomodation), 'dd/MM/yyyy', { locale: ptBR });
      const endDate = this.batch.date_departure 
        ? format(parseISO(this.batch.date_departure), 'dd/MM/yyyy', { locale: ptBR })
        : 'Lote em Andamento';
      
      return `${startDate} - ${endDate}`;
    },
    chartStartDate() {
      return this.startDate 
        ? this.formatToYYYYMMDD(this.startDate) 
        : this.formatToYYYYMMDD(this.batch.date_accomodation);
    },
    chartEndDate() {
      return this.endDate 
        ? this.formatToYYYYMMDD(this.endDate)
        : (this.batch.date_departure 
            ? this.formatToYYYYMMDD(this.batch.date_departure) 
            : this.formatToYYYYMMDD(new Date()));
    },
  },
  watch: {
    seekNewRange: {
      handler: async function() {
          this.forceRerenderUnload();
      }
    }
  },
  async created() {
    let today = new Date();
    const today_date = format(today, 'yyyy-MM-dd');
    const barn = this.barn.pk;
    
    const accommodation = this.startDate 
      ? this.formatToYYYYMMDD(this.startDate)
      : this.formatToYYYYMMDD(this.batch.date_accomodation);
      
    const departure = this.endDate 
      ? this.formatToYYYYMMDD(this.endDate)
      : (this.batch.date_departure != null 
          ? this.formatToYYYYMMDD(this.batch.date_departure) 
          : today_date);

    try {
      await this.$store.dispatch("fetchFeedConsolidatedData", {
        barn,
        accommodation,
        departure,
      });
    } catch(err) {
      this.fetchFailed = true;
    }

    this.loading = false;
  },
};
</script>

<style scoped>
.chart-without-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.chart-active {
  margin-left: 3%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  height: 24px;
  margin-bottom: 12px;
}

.chart-active .chart-title {
  display: flex;
  align-items: center;
}

.chart-active .chart-title p {
  margin: 0;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.error-message {
  font-size: 2rem;
  color: #952323;
}

.mobile-info {
  display: none;
}

.situacao-atual-feed-bom {
  background: #78b843 !important;
}

.situacao-atual-feed-medio {
  background: #e2bf65 !important;
}

.situacao-atual-feed-ruim {
  background: #a94949 !important;
}

strong {
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .silo {
    display: none;
  }

  .mobile-info {
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }

  .export-btn {
    padding: 8px;
    min-width: 50px;
  }

  .export-btn span {
    display: none;
  }

  .export-btn i:last-child {
    display: none;
  }

  .dropdown-content {
    width: 50px;
  }

  .dropdown-content a {
    padding: 12px;
    justify-content: center;
  }

  .dropdown-content a span {
    display: none;
  }

  .dropdown-content i {
    font-size: 1.2rem;
  }

  .header-section {
    width: 50px;
  }
}

@media screen and (max-width: 480px) {
  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 10px;
  }
}


.chart-title-main {
  text-align: center;
  margin: 20px 0;
}

.chart-title-main h2 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.chart-subtitle {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
  font-style: italic;
}

.chart-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}


.chart-title {
  text-align: center;
  margin: 0 auto;
}

.export-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
}

.export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: #1b4238;
  color: white;
  min-width: 50px;
  justify-content: center;
}

.export-btn:hover {
  background-color: #218838;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-content a:hover {
  background-color: #f8f9fa;
}

.dropdown-content i {
  width: 16px;
  text-align: center;
}

.dropdown-content a:first-child i {
  color: #dc3545;
}

.dropdown-content a:last-child i {
  color: #28a745;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 140px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
}
</style>
