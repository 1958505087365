import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { logoPecsmart } from '../base64/logoPecsmart';

pdfMake.vfs = pdfFonts?.pdfMake?.vfs;


export const generateConsolidatePDF = (data, shouldDownload = false, workingSensors, allFoods, locationInfo) => {
  if (!data || data.length === 0) {
    console.error('No data to export');
    return;
  }
  
  // Get date range
  const dates = data.map(row => new Date(row.date));
  const startDate = new Date(Math.min(...dates)).toLocaleDateString();
  const endDate = new Date(Math.max(...dates)).toLocaleDateString();
  
  // Create filename with location information
  const fileName = `Consumo consolidado - ${locationInfo.farmName} - ${locationInfo.barnName} - Batch ${locationInfo.batchName} - ${startDate} - ${endDate}.pdf`;

  // Create a map of food ID to name
  const foodNamesMap = allFoods.reduce((map, food) => {
    map[food.pk] = food.name;
    return map;
  }, {});

  const documentDefinition = {
    pageSize: 'A4',
    pageMargins: [ 40, 60, 40, 20 ],
    content: [
      {
        columns: [
          {
            image: logoPecsmart,
            width: 120,
            margin: [0, 0, 0, 20],
            alignment: 'center'
          }
        ],
        alignment: 'center'
      },
      {
        text: 'Consumo consolidado',
        style: 'mainHeader',
        alignment: 'center',
        margin: [0, 0, 0, 5]
      },
      {
        text: `${locationInfo.farmName} - ${locationInfo.barnName} - Lote ${locationInfo.batchName}`,
        style: 'locationInfo',
        alignment: 'center',
        margin: [0, 0, 0, 5]
      },
      {
        text: `${startDate} - ${endDate}`,
        style: 'subHeader',
        alignment: 'center',
        margin: [0, 0, 0, 20]
      },
      {
        table: {
          headerRows: 1,
          widths: [
            '30%',
            '30%',
            '40%'
          ],
          body: [
            [
              { text: 'Data', style: 'tableHeader', alignment: 'center' },
              { text: 'Consumo total (kg)', style: 'tableHeader', alignment: 'center' },
              { text: 'Ração', style: 'tableHeader', alignment: 'center' }
            ],
            ...data.map(row => [
              { text: new Date(row.date).toLocaleDateString(), alignment: 'center' },
              { text: row.consume.toFixed(2), alignment: 'center' },
              { text: foodNamesMap[row.food_id] || `Feed ${row.food_id}`, alignment: 'center' }
            ])
          ]
        },
        layout: {
          fillColor: function(rowIndex) {
            return (rowIndex % 2 === 0) ? '#FFFFFF' : '#F8F9FA';
          }
        }
      }
    ],
    styles: {
      mainHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 0]
      },
      locationInfo: {
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 0]
      },
      subHeader: {
        fontSize: 12,
        italic: true,
        color: '#666666',
        margin: [0, 5, 0, 0]
      },
      header: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 0]
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
        fillColor: '#eeeeee'
      }
    },
    defaultStyle: {
      fontSize: 10
    }
  };

  const pdfDoc = pdfMake.createPdf(documentDefinition);
  
  if (shouldDownload) {
    pdfDoc.download(fileName);
  } else {
    pdfDoc.open();
  }
};
