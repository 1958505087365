export const retrySeveralTimes = async ({ url, headers, retryAttempts, delay }) => {
  let lastError;
  
  for (let attempt = 0; attempt < retryAttempts; attempt++) {
    try {
      const response = await fetch(url, { headers });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result?.error || result?.status) {
        throw new Error(result.error || result.status);
      }
      
      return result;
    } catch (error) {
      lastError = error;
      if (attempt < retryAttempts - 1) {
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  }
  
  throw lastError;
}; 