import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const generateConsolidateExcel = async (data, workingSensors, allFoods, locationInfo) => {
  if (!data || data.length === 0) {
    console.error('No hay datos para exportar');
    return;
  }

  // Obtener el rango de fechas
  const dates = data.map(row => new Date(row.date));
  const startDate = new Date(Math.min(...dates)).toLocaleDateString();
  const endDate = new Date(Math.max(...dates)).toLocaleDateString();
  
  // Crear nombre del archivo con la información de ubicación
  const fileName = `Consumo Consolidado - ${locationInfo.farmName} - ${locationInfo.barnName} - Lote ${locationInfo.batchName} - ${startDate} - ${endDate}.xlsx`;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Consumo Consolidado');

  // Crear un mapa de ID de alimento a nombre
  const foodNamesMap = allFoods.reduce((map, food) => {
    map[food.pk] = food.name;
    return map;
  }, {});

  // Configurar las columnas
  const columns = [
    { key: 'date', width: 15 },
    { key: 'consume', width: 20 },
    { key: 'food_name', width: 40 }
  ];

  worksheet.columns = columns;

  // Agregar título y rango de fechas
  const titleCell = worksheet.getCell('B1');
  titleCell.value = 'Consumo Consolidado';
  titleCell.font = { bold: true, size: 14 };
  titleCell.alignment = { horizontal: 'center' };

  // Agregar información de ubicación
  const locationCell = worksheet.getCell('B2');
  locationCell.value = `${locationInfo.farmName} - ${locationInfo.barnName} - Lote ${locationInfo.batchName}`;
  locationCell.font = { bold: true };
  locationCell.alignment = { horizontal: 'center' };

  const dateRangeCell = worksheet.getCell('B3');
  dateRangeCell.value = `${startDate} - ${endDate}`;
  dateRangeCell.font = { italic: true };
  dateRangeCell.alignment = { horizontal: 'center' };

  // Ajustar ancho de la columna B
  worksheet.getColumn('B').width = 40;

  // Establecer los headers en la fila 5 (movido una fila más abajo)
  const headerRow = worksheet.getRow(5);
  headerRow.values = ['Data', 'Consumo Total (kg)', 'Ração'];
  headerRow.font = { bold: true };
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFEEEEEE' }
  };

  // Agregar datos
  data.forEach((row, index) => {
    const rowData = {
      date: new Date(row.date).toLocaleDateString(),
      consume: Number(row.consume.toFixed(2)),
      food_name: foodNamesMap[row.food_id] || `Ração ${row.food_id}`,
    };

    const currentRow = worksheet.getRow(6 + index);
    currentRow.values = [rowData.date, rowData.consume, rowData.food_name];
  });

  // Alinear columnas
  worksheet.getColumn('date').alignment = { horizontal: 'center' };
  worksheet.getColumn('consume').alignment = { horizontal: 'center' };
  worksheet.getColumn('food_name').alignment = { horizontal: 'center' };
  
  // Auto-fit the food name column
  worksheet.getColumn('food_name').width = Math.max(
    40, // minimum width
    ...data.map(row => (foodNamesMap[row.food_id] || `Ração ${row.food_id}`).length + 2) // +2 for padding
  );

  // Alternar colores de fila
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 5) { // Skip header and empty rows
      const fill = rowNumber % 2 === 0 
        ? { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F9FA' } }
        : { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
      row.eachCell(cell => {
        cell.fill = fill;
      });
    }
  });

  // Guardar el archivo
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), fileName);
}; 